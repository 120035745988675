export const MUTATION_TYPES = {
  SET_USER: "SET_USER",
  SET_DESIGN_FLAG: "SET_DESIGN_FLAG",
  SET_BREAK_POINT: "SET_BREAK_POINT",
  SHOW_MODAL: "SHOW_MODAL",
};

export const ACTION_TYPES = {
  LOGIN: "LOGIN",
  GET_BREAK_POINT: "GET_BREAK_POINT",
  SHOW_MODAL: "SHOW_MODAL",
};
