<template>
  <div>
    <button
      :is="to ? 'router-link' : isHref ? 'a' : 'button'"
      :to="to"
      class="base-button"
      :class="{
        '__button-menu': buttonMenu,
        'router-link-active': dropdownMenu.length > 0 && isDropdownOpen,
        'dropdown-button': dropdownMenu.length > 0,
        rounded: rounded,
        'content-after': contentAfter,
      }"
      :disabled="disabled"
      @click="openDropDownMenu"
    >
      <template>
        <base-icon
          v-if="startIconPath"
          class="base-button__start-icon"
          :path="startIconPath"
          :width="iconSize"
          :height="iconSize"
        />
        <slot class="base-button__title"></slot>
        <slot name="label" class="base-button__end-label"></slot>
        <base-icon
          v-if="dropdownMenu.length > 0"
          class="base-button__end-icon"
          :class="{ __opened: isDropdownOpen }"
          width="24"
          height="24"
          :path="'navBar/CaretUp.svg'"
        />
      </template>
    </button>
    <transition name="fade">
      <dropdown-menu
        v-if="dropdownMenu.length > 0 && isDropdownOpen"
        :is-dropdown-open="isDropdownOpen"
        :dropdown-menu="dropdownMenu.filter(item => item.showFlag)"
        :button-menu="buttonMenu"
        :rounded="rounded"
        @closeMobileMenu="$emit('closeMobileMenu')"
      />
    </transition>
  </div>
</template>
<script lang="js">
import { defineComponent, ref } from "vue";
import BaseIcon from "@/components/BaseIcon";
import DropdownMenu from "./DropdownMenu";
import { buttonProps } from "@/lib/helpers/propsTypes";
export default defineComponent({
  name: "BaseButtonOld",
  components: { DropdownMenu, BaseIcon },
  props: {
    ...buttonProps
  },
  setup() {
    const isDropdownOpen = ref(false);
    const openDropDownMenu = () => {
      isDropdownOpen.value = !isDropdownOpen.value;
    }
    return {
      isDropdownOpen,
      openDropDownMenu,
    }
  }
});
</script>
<style lang="scss">
@import "@/styles/baseButton.scss";
</style>
