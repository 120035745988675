<template>
  <full-screen
    :showFullScreen="showInFullScreen"
    :imgPath="
      info && info.photo === null
        ? require(`@/assets/icons/defaultAvatar/DefaultAvatar.png`)
        : `${info.photopath}${info.photo}`
    "
    @hideFullScreen="showInFullScreen = false"
  >
    <div
      class="base-avatar"
      :class="{ __reverse: reverseAvatar, '__full-screen': showInFullScreen }"
      @click="showInFullScreen = true"
    >
      <img
        v-if="info && info.photo === null"
        class="base-avatar__img"
        :src="require(`@/assets/icons/defaultAvatar/DefaultAvatar.png`)"
        :style="screenSizeDependency ? { width: `${size}px`, height: `${size}px` } : {}"
        alt=""
      />
      <img
        v-else
        class="base-avatar__img"
        :src="`${info.photopath}${info.photo}`"
        :style="screenSizeDependency ? { width: `${size}px`, height: `${size}px` } : {}"
        alt=""
      />
      <div v-if="showAvatarInfo" class="base-avatar__info">
        <div
          class="base-avatar__name truncate-ellipsis"
          :style="{ color: nameColor, fontSize: `${nameSize}px`, fontWeight }"
        >
          {{ info.nickname }}
        </div>
        <div v-if="showJobTitle" class="base-avatar__job-title truncate-ellipsis">{{ info.job_name }}</div>
      </div>
    </div>
  </full-screen>
</template>
<script lang="js">
import { defineComponent, ref } from "vue";
import FullScreen from "@/components/FullScreen";

export default defineComponent({
  name: "BaseAvatar",
  components: { FullScreen },
  props: {
    size: {
      type: String,
      default: "60",
    },
    showAvatarInfo: {
      type: Boolean,
      default: true,
    },
    reverseAvatar: {
      type: Boolean,
      default: false,
    },
    nameColor: {
      type: String,
    },
    nameSize: {
      type: String,
    },
    fontWeight: {
      type: String,
      default: '600'
    },
    screenSizeDependency: {
      type: Boolean,
    },
    showJobTitle: {
      type: Boolean,
      default: true,
    },
    info: {
      type: Object,
      default: () => {
        return {
        "nickname": "",
        "job_name": "",
        "photo": null,
        "photopath": "",
        }
      }
    }
  },
  setup() {
    const showInFullScreen = ref(false);
    return {
      showInFullScreen
    }
  }
})
</script>
<style lang="scss" scoped>
.base-avatar {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  &__img {
    width: 60px;
    height: 60px;
    object-fit: cover;
    border-radius: 50%;
  }
  &__info {
    margin-left: 16px;
  }
  &.__reverse {
    flex-direction: row-reverse;
    text-align: end;
    gap: 16px;
  }
  &.__full-screen {
  }
  &__name {
    display: block;
    max-width: 230px;
    color: #ffffff;
    font-family: Inter, serif;
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;
  }
  &__job-title {
    display: block;
    max-width: 230px;
    color: #94a3b8;
    font-family: Inter, serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 28px;
  }
  @media (max-width: 1440px) {
    &__img {
      width: 40px;
      height: 40px;
    }
    &__name {
      color: #ffffff;
      font-family: Inter, serif;
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
    }
    &__job-title {
      color: #94a3b8;
      font-family: Inter, serif;
      font-size: 12px;
      font-weight: 500;
      line-height: 24px;
    }
    &__info {
      margin-left: 12px;
    }
  }
  @media (max-width: 980px) {
    &__img {
      width: 24px;
      height: 24px;
    }
    &__name {
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
    }
    &.__reverse {
      gap: 12px;
    }
  }
}
</style>
