<template>
  <div id="app" :class="{ 'app-old': !isNewDesign, 'app-new': isNewDesign }">
    <!--    todo Сделать одельный компонент mobileHeader-->
    <div v-if="showMobileHeader" class="mobile-header">
      <base-icon class="mobile-header__list" :path="'navBar/List.svg'" @click="openMenu(false)" />
      <router-link to="Today" class="mobile-header__title-label">
        <span class="mobile-header__title-brew">Brew</span>
        <span class="mobile-header__title-ninja">Ninja</span>
      </router-link>
      <div>
        <base-avatar
          v-if="loading"
          class="mobile-header__avatar"
          size="32"
          :screen-size-dependency="true"
          :show-avatar-info="false"
          :info="user"
        />
      </div>
    </div>
    <!--    todo Сделать одельный компонент navbar-->
    <div v-if="showNavBar" id="nav" class="nav title noselect" :class="{ __mobile: isMobile }">
      <div class="nav__title">
        <base-icon class="nav__close" v-if="isMobile" :path="'Close.svg'" @click="openMenu(false)" />
        <div v-if="!isMobile" class="nav__title-label">
          <div class="nav__title-ninja">Brew Ninja</div>
          <div class="nav__title-brew">{{ user.business }}</div>
        </div>
      </div>
      <div class="nav__wrapper">
        <div class="nav__overlay" @click="openMenu(false)"></div>
        <template v-for="item in Links">
          <div v-if="item.showFlag" :key="item.to" class="nav__item" @click="openMenu(item.dropdownMenu)">
            <div
              v-if="['adminRoom', 'logout'].includes(item.name) && !isMobile"
              class="nav__divider"
            ></div>
            <base-button
              class="nav__item-button"
              :to="item.to"
              :key="item.to"
              :start-icon-path="item.startIcon"
              :dropdown-menu="item.dropdownMenu"
              :rounded="!isMobile"
              :content-after="!isMobile"
              :icon-size="isMobile ? '24' : '32'"
              :class="{ navlogout: item.to === '/logout' }"
              buttonMenu
              @closeMobileMenu="openMenu(false)"
            >
              {{ item.title }}
            </base-button>
          </div>
        </template>
      </div>
      <base-avatar class="nav__avatar" v-if="!isMobile" :info="user" />
    </div>
    <div class="wide" :class="{ '__full-width': currentRouteLogin }">
      <router-view />
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, computed, onMounted, watch, onUnmounted, onUpdated } from "vue";
import { useRoute } from "vue2-helpers/vue-router";
import { useStore } from "vue2-helpers/vuex";
import { ACTION_TYPES, MUTATION_TYPES } from "@/store/const";
import { userDataGet } from "@/components-js/requestSrv";
import { DEVICE_TYPES } from "@/lib/helpers/checkDevice";
import { navLinks } from "@/config";
import BaseButton from "@/components/BaseButton";
import BaseIcon from "@/components/BaseIcon";
import BaseAvatar from "@/components/BaseAvatar";
import "./styles/app.scss";

export default defineComponent({
  name: "App",
  components: { BaseAvatar, BaseIcon, BaseButton },
  async updated() {
    // todo переделать на глобальное хранилище vuex, в дальнейшем будет мешать переходу на vue3
    this.$root.user = await userDataGet();
  },
  setup() {
    const store = useStore();
    store.dispatch(ACTION_TYPES.LOGIN);
    const route = useRoute();

    const user = ref({});
    const loading = ref(false);
    const links_flag = ref(false);
    const isMenuOpen = ref(false);

    const isMobile = computed(() => store?.state?.breakPoint === DEVICE_TYPES.MOBILE);
    const isTablet = computed(() => store?.state?.breakPoint === DEVICE_TYPES.TABLET);
    const showMobileHeader = computed(() => (isMobile.value || isTablet.value) && links_flag.value);
    const showNavBar = computed(() => (isMobile.value || isTablet.value ? isMenuOpen.value : links_flag.value));
    const isNewDesign = computed(() => store?.state?.isNewDesign);
    const currentRoute = computed(() => route.name);
    const Links = computed(() => navLinks);
    const currentRouteLogin = computed(() => route.name === "Login");

    const showReleaseInfo = () => {
      const base64ToBytes = base64 => {
        const binString = atob(base64);
        return Uint8Array.from(binString, m => m.codePointAt(0));
      };

      const encodedData = document.querySelector('meta[name="version"]')?.content;
      const decodedData = !!encodedData ? new TextDecoder().decode(base64ToBytes(encodedData)) : undefined;

      if (!!decodedData) {
        console.log("data", JSON.parse(decodedData));
      }
    };

    const openMenu = hasItemDropDown => {
      if (!hasItemDropDown) {
        isMenuOpen.value = !isMenuOpen.value;
      }
    };

    const handleResize = () => {
      store.dispatch(ACTION_TYPES.GET_BREAK_POINT);
    };

    watch(currentRoute, async () => {
      try {
        user.value = await userDataGet();
      } finally {
        loading.value = true;
      }
      if (route.meta.showMenu) {
        //const rolesUser = await rolesGet();
        for (let l of Links.value) {
          console.log('watch');
          if (l.hidden) continue;
          l.showFlag = l.roles.map(r => user.value.roles.includes(r)).includes(true);
          if (!user.value.brew && l.to === "/wiki") {
            l.showFlag = false;
          }
          if (l.paidFeature && !user.value.brew) {
            l.showFlag = false;
          }
          if (l.dropdownMenu && l.dropdownMenu.length > 0) {
            l.dropdownMenu = l.dropdownMenu.map(item => {
              if (item.paidFeature) {
                !!user.value.brew ? (item.showFlag = true) : (item.showFlag = false);
              } else {
                item.showFlag = item.roles.map(r => user.value.roles.includes(r)).includes(true);
              }
              return item;
            });
          }
          if (user.value.uid === -1) {
            l.showFlag = l.uid === -1;
          }
        }
      }
      links_flag.value = route.meta.showMenu;
    });

    onUpdated(() => {
      store.commit(MUTATION_TYPES.SET_DESIGN_FLAG, route?.meta?.isNewDesign);
    });

    onMounted(async () => {
      const runOnKeys = (func, ...codes) => {
        let pressed = new Set();

        document.addEventListener("keydown", function(event) {
          pressed.add(event.code);
          for (let code of codes) {
            if (!pressed.has(code)) {
              return;
            }
          }
          pressed.clear();

          func();
        });

        document.addEventListener("keyup", function(event) {
          pressed.delete(event.code);
        });
      };
      runOnKeys(showReleaseInfo, "Enter", "ControlLeft");

      window.addEventListener("resize", handleResize);
      handleResize();
      try {
        user.value = await userDataGet();
      } finally {
        loading.value = true;
      }
      if (route.meta.showMenu) {
        for (let l of Links.value) {
          if (l.hidden) continue;
          l.showFlag = l.roles.map(r => user.value.roles.includes(r)).includes(true);
          if (!user.value.brew && l.to === "/wiki") {
            l.showFlag = false;
          }
          if (l.paidFeature && !user.value.brew) {
            l.showFlag = false;
          }
          if (l.dropdownMenu && l.dropdownMenu.length > 0) {
            l.dropdownMenu = l.dropdownMenu.map(item => {
              if (item.paidFeature) {
                !!user.value.brew ? (item.showFlag = true) : (item.showFlag = false);
              } else {
                item.showFlag = item.roles.map(r => user.value.roles.includes(r)).includes(true);
              }
              return item;
            });
          }
          if (user.value.uid === -1) {
            l.showFlag = l.uid === -1;
          }
        }
      }
      links_flag.value = route.meta.showMenu;
    });

    onUnmounted(() => {
      window.removeEventListener("resize", handleResize);
    });

    return {
      user,
      Links,
      loading,
      isNewDesign,
      isMobile,
      links_flag,
      isMenuOpen,
      currentRouteLogin,
      showNavBar,
      showMobileHeader,
      openMenu,
    };
  },
});
</script>
<style lang="scss">
@import "@/styles/oldStyles";
@import "@/styles/newStyles";

.app-old {
  @include oldStyle;
}

.app-new {
  @include newStyle;
}
</style>
