import { ACTION_TYPES, MUTATION_TYPES } from "@/store/const";
import { getBreakPoint } from "@/lib/helpers/checkDevice";

export const actions = {
  [ACTION_TYPES.LOGIN](context) {
    context.commit(MUTATION_TYPES.SET_USER);
  },
  [ACTION_TYPES.GET_BREAK_POINT](context) {
    context.commit(MUTATION_TYPES.SET_BREAK_POINT, getBreakPoint());
  },
};
